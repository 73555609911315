<template>
  <div class="subgrid">
    <!-- Water Consumption Chart -->
    <div class="chart-box" :class="{ 'greyed-out-chart': !hasData }">
      <h6>{{ $t('dashboard.waterDashboard.waterConsumption') }}</h6>
      <div class="chart-placeholder" v-if="!hasData" style="z-index: 2;">
        {{ $t('dashboard.noData') }}
      </div>
      <div class="mt-auto mb-auto">
        <highcharts :options="waterConsumptionChartOptions" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Highcharts from 'highcharts';

export default {
  props: {
    meteringPointIds: {
      type: Array,
      required: true
    },
    comparisonMode: {
      type: String,
      required: true
    },
    getDefaultChartOptions: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      hasData: false,
      waterConsumptionChartOptions: this.getDefaultChartOptions()
    };
  },
  created() {
    this.fetchMeteringPointData();
  },
  watch: {
    meteringPointIds: {
      handler: 'updateChart',
      immediate: true
    },
    comparisonMode: {
      handler: 'updateChart',
      immediate: true
    }
  },
  methods: {
    async updateChart() {
      if (this.loading || !this.meteringPointIds?.length) {
        return;
      }

      this.loading = true;
      try {
        const { dateTimeFrom, dateTimeTo, previousDateTimeFrom, previousDateTimeTo } = this.calculateDateRanges();
        const requests = this.buildRequests(dateTimeFrom, dateTimeTo, previousDateTimeFrom, previousDateTimeTo);
        
        const responses = await Promise.all(requests);
        this.processResponses(responses);
      } catch (error) {
        console.error('Error updating chart:', error);
      } finally {
        this.loading = false;
      }
    },

    calculateDateRanges() {
      const today = new Date();
      let startDate, endDate, previousStartDate, previousEndDate;

      if (this.comparisonMode === 'month') {
        startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        endDate = new Date(today);
        previousStartDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        previousEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
      } else {
        startDate = new Date(today.getFullYear(), 0, 1);
        endDate = new Date(today);
        previousStartDate = new Date(today.getFullYear() - 1, 0, 1);
        previousEndDate = new Date(today.getFullYear() - 1, 11, 31);
      }

      return {
        dateTimeFrom: startDate.toISOString(),
        dateTimeTo: endDate.toISOString(),
        previousDateTimeFrom: previousStartDate.toISOString(),
        previousDateTimeTo: previousEndDate.toISOString()
      };
    },

    buildRequests(dateTimeFrom, dateTimeTo, previousDateTimeFrom, previousDateTimeTo) {
      return this.meteringPointIds.flatMap(meteringPointId => [
        axios.get(`/devices/${meteringPointId}/metering-point-values`, {
          params: {
            dateTimeFrom,
            dateTimeTo,
            aggregateWindowDuration: "1",
            aggregateWindowDurationUnit: this.comparisonMode === 'month' ? "DAYS" : "MONTHS"
          }
        }),
        axios.get(`/devices/${meteringPointId}/metering-point-values`, {
          params: {
            dateTimeFrom: previousDateTimeFrom,
            dateTimeTo: previousDateTimeTo,
            aggregateWindowDuration: "1",
            aggregateWindowDurationUnit: this.comparisonMode === 'month' ? "DAYS" : "MONTHS"
          }
        })
      ]);
    },

    processResponses(responses) {
      const combinedData = {
        currentPeriod: 0,
        previousPeriod: 0
      };

      responses.forEach((response, index) => {
        const isCurrent = index % 2 === 0;
        const periodKey = isCurrent ? 'currentPeriod' : 'previousPeriod';

        response.data.forEach(item => {
          if (item.field === "meter_volume") {
            combinedData[periodKey] += parseFloat(item.value) || 0;
          }
        });
      });

      this.updateChartOptions(combinedData);
    },

    updateChartOptions(combinedData) {
      const newOptions = {
        ...this.getDefaultChartOptions(),
        series: [{
          data: [
            {
              name: this.formatPeriodLabel(false),
              y: combinedData.previousPeriod
            },
            {
              name: this.formatPeriodLabel(true),
              y: combinedData.currentPeriod
            }
          ]
        }],
        tooltip: {
          useHTML: true,
          style: {
              fontSize: '0.85rem',
          },
          formatter: function() {
            return `<b>${this.point.name}:</b> ${Highcharts.numberFormat(this.y, 2)} m3`;
          }
        }
      };

      this.waterConsumptionChartOptions = newOptions;
      this.hasData = newOptions.series[0].data.some(data => data.y > 0);
    },

    formatPeriodLabel(isCurrent) {
      const today = new Date();
      if (this.comparisonMode === 'month') {
        const month = isCurrent ? today.getMonth() : today.getMonth() - 1;
        const year = isCurrent ? today.getFullYear() : today.getFullYear() - (month < 0 ? 1 : 0);
        const monthName = new Date(year, month < 0 ? 11 : month).toLocaleString('default', { month: 'long' });
        return `${monthName} ${year}`;
      } else {
        return isCurrent ? `${today.getFullYear()}` : `${today.getFullYear() - 1}`;
      }
    }
  }
};
</script>

<style scoped>

.subgrid{
  display: contents;
}

.dashboard-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}
.chart-box {
  display: flex;
  flex-direction: column;
  border: 1px solid #e7eaec;
  padding: 15px;
  border-radius: 5px;
  background: #fff;
  position: relative;
  height: 100%;
}
.chart-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #888;
  text-align: center;
  z-index: 3;
}
.greyed-out-chart {
  opacity: 0.3;
}
</style>
