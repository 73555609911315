<template>
  <nav class="navbar-default navbar-static-side" role="navigation">
    <div class="sidebar-collapse">
      <ul class="nav metismenu" id="side-menu">
        <li class="nav-header">
          <div class="dropdown profile-element">
            <div class="imgw">
              <img :src="require('@/assets/custom/img/climify_logo_white.svg')" />
              <span class="text-white block">{{ $store.state.userInfo.firstName }} {{ $store.state.userInfo.lastName }} ({{$store.state.userInfo.username}})</span>
            </div>
          </div>
          <div class="logo-element _logo-climify-small">
            <div class="imgw">
              <img :src="require('@/assets/custom/img/climify_logo_white_small.svg')" />
            </div>
          </div>
        </li>
        <li :class="{active: $route.name === 'apps'}">
          <router-link to="/apps" :title="$t('menu.apps')">
            <i class="fa fa-bookmark"></i><span class="nav-label">{{ $t('menu.apps') }}</span>
          </router-link>
        </li>
        <li :class="{active: $route.name === 'dashboard'}" v-if="isContextOrgAdmin || isBuildingAdminInContextOrg">
          <router-link to="/analytics/dashboard">
            <i class="fa fa-house"></i>
            <span class="nav-label">{{$t('menu.analytics.dashboard')}}</span>
          </router-link>
        </li>
        <li>
          <a href="#" :title="$t('menu.graphs.title')">
            <i class="fa fa-line-chart"></i><span class="nav-label">{{ $t('menu.measurements.title') }}</span><i class="fa arrow"></i>
          </a>
          <ul class="nav nav-second-level collapse">
            <li :class="{active: $route.name === 'indoorClimate'}">
              <router-link to="/indoor-climate" :title="$t('menu.indoorClimate')">{{ $t('menu.indoorClimate') }}</router-link>
            </li>
            <li :class="{active: $route.name === 'graphsLocation'}">
              <router-link to="/graphs/location" :title="$t('menu.graphs.location')">{{ $t('menu.graphs.location') }}</router-link>
            </li>
            <li :class="{active: $route.name === 'graphsLocationComparison'}">
              <router-link to="/graphs/location-comparison" :title="$t('menu.graphs.locationComparison')">{{ $t('menu.graphs.locationComparison') }}</router-link>
            </li>
            <li :class="{active: $route.name === 'graphsMeteringPoint'}">
              <router-link to="/graphs/metering-points" :title="$t('menu.graphs.meteringPoints')">{{ $t('menu.graphs.meteringPoints') }}</router-link>
            </li>
          </ul>
        </li>
        <li>
          <a href="#" :title="$t('menu.analytics.title')">
            <i class="fa fa-file-waveform"></i><span class="nav-label">{{ $t('menu.analytics.title') }}</span><i class="fa arrow"></i>
          </a>
          <ul class="nav nav-second-level collapse">
            <li :class="{active: $route.name === 'analyticsStatistics'}">
              <router-link to="/analytics/statistics" :title="$t('menu.analytics.statistics')">{{ $t('menu.analytics.statistics') }}</router-link>
            </li>
            <li :class="{active: $route.name === 'analyticsHourlyHeatmaps'}">
              <router-link to="/analytics/hourly-heatmaps" :title="$t('menu.analytics.hourlyHeatmaps')">{{ $t('menu.analytics.hourlyHeatmaps') }}</router-link>
            </li>
            <li :class="{active: $route.name === 'analyticsHeatmaps'}">
              <router-link to="/analytics/heatmaps" :title="$t('menu.analytics.heatmaps')">{{ $t('menu.analytics.heatmaps') }}</router-link>
            </li>
            <li :class="{active: $route.name === 'messageCentre'}" v-if="hasRole('ADMIN')">
              <router-link to="/message-centre" :title="$t('menu.messageCentre')">{{ $t('menu.messageCentre') }}</router-link>
            </li>
            <li :class="{active: $route.name === 'reporting'}" v-if="isContextOrgAdmin || isBuildingAdminInContextOrg">
              <router-link to="/reporting">{{ $t('reporting.navBar') }}</router-link>
            </li>
            <li :class="{active: $route.name === 'energySavings'}" v-if="isContextOrgAdmin || isBuildingAdminInContextOrg">
              <router-link to="/analytics/energy-savings">{{ $t('menu.analytics.energySavings') }}</router-link>
            </li>
          </ul>
        </li>
        <li>
          <a href="#" :title="$t('menu.control.title')" v-if="isContextOrgAdmin || isBuildingAdminInContextOrg">
            <i class="fa fa-sliders"></i><span class="nav-label">{{ $t('menu.control.title') }}</span><i class="fa arrow"></i>
          </a>
          <ul class="nav nav-second-level collapse">
            <li :class="{active: $route.name === 'schedulerProfiles'}" v-if="isContextOrgAdmin || isBuildingAdminInContextOrg">
              <router-link to="/schedulers" :title="$t('menu.schedulers')">{{ $t('menu.schedulers') }}</router-link>
            </li>
          </ul>
        </li>
        <li>
          <a href="#" :title="$t('menu.analytics.title')" v-if="isContextOrgAdmin || isBuildingAdminInContextOrg">
            <i class="fa fa-comment-dots"></i><span class="nav-label">{{ $t('menu.feedback.title') }}</span><i class="fa arrow"></i>
          </a>
          <ul class="nav nav-second-level collapse">
            <li :class="{active: $route.name === 'feedback'}" v-if="isContextOrgAdmin || isBuildingAdminInContextOrg">
              <router-link to="/feedback" :title="$t('menu.feedback.userFeedback')">{{ $t('menu.feedback.userFeedback') }}</router-link>
            </li>
            <li :class="{active: $route.name === 'forms'}" v-if="isContextOrgAdmin || isBuildingAdminInContextOrg">
              <router-link to="/forms" :title="$t('menu.forms')">{{ $t('menu.forms') }}</router-link>
            </li>
          </ul>
        </li>
       
        <li v-if="isContextOrgAdmin || isBuildingAdminInContextOrg">
          <a href="#" :title="$t('menu.management.title')">
            <i class="fa fa-user-gear"></i><span class="nav-label">{{ $t('menu.management.title') }}</span><i class="fa arrow"></i>
          </a>
          <ul class="nav nav-second-level collapse">
            <li :class="{active: $route.name === 'buildings'}" v-if="isContextOrgAdmin || isBuildingAdminInContextOrg">
              <router-link to="/buildings" :title="$t('menu.buildings')">{{ $t('menu.buildings') }}</router-link>
            </li>
            <li :class="{active: $route.name === 'devices'}" v-if="isContextOrgAdmin || isBuildingAdminInContextOrg">
              <router-link to="/devices" :title="$t('menu.devices')">{{ $t('menu.devices') }}</router-link>
            </li>
            <li :class="{active: $route.name === 'shareData'}" v-if="isContextOrgAdmin || isBuildingAdminInContextOrg">
              <router-link to="/share-data" :title="$t('menu.shareData')">{{ $t('menu.shareData') }}</router-link>
            </li>
            <li :class="{active: $route.name === 'locationProfiles'}" v-if="isContextOrgAdmin || isBuildingAdminInContextOrg">
              <router-link to="/profiles" :title="$t('menu.profiles')">{{ $t('menu.profiles') }}</router-link>
            </li>
            <li :class="{active: $route.name === 'integrations'}" v-if="isContextOrgAdmin">
              <router-link to="/integrations" :title="$t('menu.integrations')">{{ $t('menu.integrations') }}</router-link>
            </li>
            <li :class="{active: $route.name === 'userManagement'}" v-if="isContextOrgAdmin || isBuildingAdminInContextOrg">
              <router-link to="/user-management" :title="$t('menu.userManagement')">{{ $t('menu.userManagement') }}</router-link>
            </li>
            <li :class="{active: $route.name === 'organisation'}" v-if="isContextOrgAdmin">
              <router-link to="/organisation" :title="$t('menu.organisation')">{{ $t('menu.organisation') }}</router-link>
            </li>
            <li :class="{active: $route.name === 'logs'}" v-if="isContextOrgAdmin">
              <router-link to="/organisation/logs" :title="$t('menu.logs')">{{ $t('menu.logs') }}</router-link>
            </li>
          </ul>
        </li>
        
        <li v-if="hasRole('ADMIN')">
          <a href="#" :title="$t('menu.systemAdmin.title')">
            <i class="fa fa-wrench"></i><span class="nav-label">{{ $t('menu.systemAdmin.title') }}</span><i class="fa arrow"></i>
          </a>
          <ul class="nav nav-second-level collapse">
            <li :class="{active: $route.name === 'systemAdmin'}" v-if="hasRole('ADMIN')">
              <router-link to="/admin/system" :title="$t('menu.systemAdmin.devices')">{{ $t('menu.systemAdmin.devices') }}</router-link>
            </li>
            <li :class="{active: $route.name === 'organisationAdmin'}" v-if="hasOneOfRoles('ADMIN')">
              <router-link to="/admin/organisations" :title="$t('menu.organisationAdmin')" @click.native="$store.commit('orgAdmin/SAVE_ORG_ADMIN_ACTIVE_TAB', 0)">{{ $t('menu.organisationAdmin') }}</router-link>
            </li>
            <li :class="{active: $route.name === 'testExternalApi'}" v-if="hasRole('ADMIN')">
              <router-link to="/admin/test-external-api" title="Test external API">{{ $t('menu.testExternalAPI') }}</router-link>
            </li>
          </ul>
        </li>
        
      </ul>
    </div>
  </nav>
</template>

<script>
import * as inspinia from '@/assets/js/inspinia'

export default {
  data() {
    return {}
  },
  mounted() {

    $('#side-menu').metisMenu();
    $("[data-toggle=popover]").popover();

    $("ul.nav-third-level > li").click(function (e) {
      if ($('body').hasClass('mini-navbar')) {
        $("body").toggleClass("mini-navbar");
        inspinia.SmoothlyMenu();
      }
    });

    $("ul.nav-second-level > li:not(:has(li))").click(function (e) {
      if ($('body').hasClass('mini-navbar')) {
        $("body").toggleClass("mini-navbar");
        inspinia.SmoothlyMenu();
      }
    });
  }
}
</script>

<style>
li.nav-header img { height: 35px; }
li.nav-header div._logo-climify-small.logo-element { padding:0; }
li.nav-header div._logo-climify-small.logo-element img { height: 50px; width: 50px; padding: 10px; }
li.nav-header { padding: 1em; }
li.nav-header div.imgw { display: flex; flex-direction: column; align-items: center; }
.mini-navbar .sidebar-collapse i.arrow { display: none; }
</style>
