<template>
  <div class="table-breakdown">
    <div class="row mt-2 mb-4">
      <div class="input-group col-lg-6 align-content-center">
        <span class="input-group-prepend">
          <b-button class="btn btn-sm btn-primary" @click="clearFilter">{{ $t('common.actions.clear') }}</b-button>
        </span>
        <b-form-input
          type="text"
          debounce="300"
          max="50"
          :placeholder="$t('enterForSearch')"
          v-model="table.filter"
          class="form-control form-control-sm"
        />
        <span class="input-group-append">
          <b-button class="btn btn-sm btn-primary" @click="fetchBuildings">
            <i class="fas fa-refresh mr-1"></i>
            <span>{{ $t('common.actions.refresh') }}</span>
          </b-button>
        </span>
      </div>
      <div class="col-lg-3 m-t-sm text-right" v-if="buildingsData && buildingsData.length">
        <row-count :size="table.size" :page="table.page" :total="buildingsData.length"></row-count>
      </div>
      <div class="col-lg-3 text-right" v-if="buildingsData && buildingsData.length && buildingsData.length > table.size">
        <b-pagination
          size="sm"
          class="m-t-xs"
          align="right"
          :total-rows="buildingsData.length"
          v-model="table.page"
          :per-page="table.size"
        ></b-pagination>
      </div>
    </div>
    <b-table
      responsive
      hover
      striped
      class="text-nowrap"
      thead-class="text-nowrap"
      :filter="table.filter"
      :fields="table.fields"
      :items="processedBuildings"
      :current-page="table.page"
      :per-page="table.size"
      :sort-by.sync="table.sortBy"
      :sort-desc.sync="table.sortDesc"
      @row-clicked="openBuildingDetails"
    >
    </b-table>

    <!-- Building Details Modal -->
    <b-modal v-model="showBuildingDetailsModal" size="xl" :title="selectedBuilding?.name" hide-footer>
      <building-dashboard
        v-if="selectedBuilding"
        :building="selectedBuilding"
        :comparisonMode="comparisonMode"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RowCount from '@/components/common/RowCount';
import BuildingDashboard from './BuildingDashboard.vue';
import axios from 'axios';
import { formatUnit } from "@/utils/converter";

export default {
  components: {
    RowCount,
    BuildingDashboard,
  },
  props: {
    comparisonMode: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
      default: "Building Energy Data",
    },
  },
  data() {
    return {
      showBuildingDetailsModal: false,
      selectedBuilding: null,
      buildingUsage: {},
      fetchedBuildings: new Set(), // Track buildings already fetched
      table: {
        filter: "",
        fields: [
          { key: "name", label: this.$t("dashboard.table.headers.buildingName"), sortable: true },
          { key: "energyUseIntensity", label: this.$t("dashboard.table.headers.energyUseIntensity"), sortable: true },
          { key: "electricity", label: this.$t("dashboard.table.headers.electricity"), sortable: true },
          { key: "districtHeating", label: this.$t("dashboard.table.headers.heating"), sortable: true },
          { key: "gas", label: this.$t("dashboard.table.headers.gas"), sortable: true },
          { key: "water", label: this.$t("dashboard.table.headers.water"), sortable: true },
          { key: "hvac", label: this.$t("dashboard.table.headers.hvac"), sortable: true },
          { key: "tailIndex", label: this.$t("dashboard.table.headers.tail"), sortable: true },
          { key: "energyTrend", label: this.$t("dashboard.table.headers.energyTrend"), sortable: true },
          { key: "temperature", label: this.$t("dashboard.table.headers.temperatureThreshold"), sortable: true },
        ],
        page: 1,
        size: 10,
        sortBy: "name",
        sortDesc: false,
      },
    };
  },
  computed: {
    ...mapState({
      buildingsData: (state) => state.buildings.buildings,
      contextOrg: (state) => state.contextOrg,
      meteringPoints: (state) => state.devices.meters,
    }),
    processedBuildings() {
      return this.buildingsData && this.buildingsData.length
        ? this.buildingsData.map((building) => {
            const electricity = this.getUsageForBuilding(building.id, 'ELECTRICITY');
            const districtHeating = this.getUsageForBuilding(building.id, 'DISTRICT_HEATING');
            const water = this.getUsageForBuilding(building.id, 'WATER');

            const formattedElectricity = formatUnit(electricity, 'kWh');
            const formattedDistrictHeating = formatUnit(districtHeating, 'kWh');
            const formattedWater = formatUnit(water, 'm3');

            return {
              id: building.id,
              name: building.name,
              energyUseIntensity: "N/A",
              electricity: `${formattedElectricity.value} ${formattedElectricity.unit}`,
              districtHeating: `${formattedDistrictHeating.value} ${formattedDistrictHeating.unit}`,
              gas: "N/A",
              water: `${formattedWater.value} ${formattedWater.unit}`,
              hvac: "N/A",
              tailIndex: "N/A",
              energyTrend: "N/A",
              temperature: "N/A",
            };
          })
        : [];
    },
  },
  watch: {
    contextOrg: {
      immediate: true,
      handler(newOrg) {
        if (newOrg && newOrg.id) {
          this.fetchBuildings();
        }
      },
    },
    buildingsData: {
      immediate: true,
      handler(newBuildings) {
        if (newBuildings && newBuildings.length) {
          newBuildings.forEach(building => {
            this.fetchUsageDataForBuilding(building.id);
          });
        }
      },
    },
    comparisonMode: {
      immediate: true,
      handler(newMode) {
        // When comparisonMode changes, reset the building usage and fetched flags, then re-fetch the building data.
        this.buildingUsage = {};
        this.fetchedBuildings.clear();
        if (this.contextOrg && this.contextOrg.id) {
          this.fetchBuildings();
        }
      },
    },
  },
  methods: {
    ...mapActions({
      fetchOrganisationBuildings: "buildings/fetchOrganisationBuildings",
    }),
    clearFilter() {
      this.table.filter = "";
    },
    fetchBuildings() {
      if (this.contextOrg && this.contextOrg.id) {
        this.fetchOrganisationBuildings(this.contextOrg.id).then(() => {
          this.buildingsData.forEach(building => {
            this.fetchUsageDataForBuilding(building.id);
          });
        }).catch((error) => {
          console.error("Error fetching buildings:", error);
        });
      }
    },
    fetchUsageDataForBuilding(buildingId) {
      // Check if the building has already been fetched before proceeding
      if (this.fetchedBuildings.has(buildingId)) {
        return;
      }

      // Add building to fetchedBuildings set to prevent duplicate fetching
      this.fetchedBuildings.add(buildingId);

      const meteringPoints = this.meteringPoints.filter(point => point.building.id === buildingId);

      if (!meteringPoints.length) {
        console.warn(`No metering points found for building ID: ${buildingId}`);
        return;
      }

      let startDate, endDate;
      const today = new Date();

      if (this.comparisonMode === 'month') {
        startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        endDate = new Date(today);
      } else if (this.comparisonMode === 'year') {
        startDate = new Date(today.getFullYear(), 0, 1);
        endDate = new Date(today);
      }

      const dateTimeFrom = startDate.toISOString();
      const dateTimeTo = endDate.toISOString();

      const requests = meteringPoints.map(meteringPoint => {
        const request = axios.get(`/devices/${meteringPoint.id}/metering-point-values`, {
          params: {
            dateTimeFrom,
            dateTimeTo,
            aggregateWindowDuration: "1",
            aggregateWindowDurationUnit: this.comparisonMode === 'month' ? "DAYS" : "MONTHS"
          }
        });
        return { request, meteringPointId: meteringPoint.id };
      });

      Promise.all(requests.map(req => req.request))
        .then(responses => {
          const newUsage = {
            ELECTRICITY: 0,
            WATER: 0,
            DISTRICT_HEATING: 0
          };

          responses.forEach((response, index) => {
            const meteringPointId = requests[index].meteringPointId;

            const meteringPoint = this.meteringPoints.find(point => point.id === meteringPointId);

            if (!meteringPoint) {
              console.warn(`Metering point metadata not found for ID: ${meteringPointId}`);
              return;
            }

            response.data.forEach(item => {
              const type = item.field;
              const value = parseFloat(item.value);

              // Update usage values based on the subtype from the original metering point data
              if (type === "meter_energy_consumption" && meteringPoint.meteringPointSubtype === 'ELECTRICITY') {
                newUsage.ELECTRICITY += value;
              } else if (type === "meter_volume" && meteringPoint.meteringPointSubtype === 'WATER') {
                newUsage.WATER += value;
              } else if (type === "meter_energy_consumption" && meteringPoint.meteringPointSubtype === 'DISTRICT_HEATING') {
                newUsage.DISTRICT_HEATING += value;
              }
            });
          });

          // Set the new usage after calculations
          this.$set(this.buildingUsage, buildingId, newUsage);
        })
        .catch(error => {
          console.error('Error fetching metering point data:', error);
        });
    },
    getUsageForBuilding(buildingId, usageType) {
      return this.buildingUsage[buildingId] ? this.buildingUsage[buildingId][usageType] : 0;
    },
    formatUsage(value) {
      return value ? value.toFixed(1) : 0.0;
    },
    openBuildingDetails(building) {
      if (building && building.id) {
        this.selectedBuilding = building;
        this.showBuildingDetailsModal = true;
      }
    },
  },
  mounted() {
    // Initialize building usage and fetch data when component is mounted
    this.buildingUsage = {};
    if (this.contextOrg && this.contextOrg.id) {
      this.fetchBuildings();
    }
  },
};
</script>

<style scoped>
.table-breakdown {
  margin-top: 20px;
}
.table-breakdown table {
  width: 100%;
  border-collapse: collapse;
}
.ibox-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top-right-controls {
  display: flex;
  margin-bottom: 15px;
  margin-left: auto;
}
.top-right-controls button {
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  width: 100px;
}
.top-right-controls button.active {
  background-color: #0056b3;
}
.input-group {
  display: flex;
  align-items: center;
}
</style>
