<template>
  <div class="dashboard-container">
    <!-- Energy Consumption Chart -->
    <div class="chart-box" :class="{ 'greyed-out-chart': !energyConsumptionChartOptions.series[0]?.data.some(data => data.y > 0) }">
      <h6>{{ $t('dashboard.energyDashboard.energyConsumption') }}</h6>
      <div class="chart-placeholder" v-if="!energyConsumptionChartOptions.series[0]?.data.some(data => data.y > 0)" style="z-index: 2;">
        {{ $t('dashboard.noData') }}
      </div>
      <highcharts :options="energyConsumptionChartOptions" />
    </div>
    <!-- CO2 Emission Total Chart -->
    <div class="chart-box" :class="{ 'greyed-out-chart': !co2EmissionTotalChartOptions.series[0]?.data.some(data => data.y > 0) }">
      <h6>{{ $t('dashboard.energyDashboard.co2EmissionTotal') }}</h6>
      <div class="chart-placeholder" v-if="!co2EmissionTotalChartOptions.series[0]?.data.some(data => data.y > 0)" style="z-index: 2;">
        {{ $t('dashboard.noData') }}
      </div>
      <highcharts :options="co2EmissionTotalChartOptions" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Highcharts from "highcharts";
import { formatUnit } from '@/utils/converter';

export default {
  props: {
    meteringPointIds: {
      type: Array,
      required: true
    },
    comparisonMode: {
      type: String,
      required: true
    },
    getDefaultChartOptions: {
      type: Function
    }
  },
  data() {
    return {
      loading: false,
      energyConsumptionChartOptions: this.getDefaultChartOptions(),
      co2EmissionTotalChartOptions: this.getDefaultChartOptions(),
    };
  },
  created() {
    if (this.meteringPointIds?.length) {
      this.fetchMeteringPointData();
    }
  },
  watch: {
    meteringPointIds: {
      handler(newVal) {
        if (newVal?.length && !this.loading) {
          this.fetchMeteringPointData();
        }
      }
    },
    comparisonMode: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && !this.loading && this.meteringPointIds?.length) {
          this.fetchMeteringPointData();
        }
      }
    }
  },
  methods: {
    async fetchMeteringPointData() {
      if (this.loading || !this.meteringPointIds?.length) {
        return;
      }

      this.loading = true;
      try {
        // Calculate dateTimeFrom and dateTimeTo for comparison
        let startDate, endDate, previousStartDate, previousEndDate;
        const today = new Date();

        if (this.comparisonMode === 'month') {
          startDate = new Date(today.getFullYear(), today.getMonth(), 1);
          endDate = new Date(today);
          previousStartDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
          previousEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
        } else if (this.comparisonMode === 'year') {
          startDate = new Date(today.getFullYear(), 0, 1);
          endDate = new Date(today);
          previousStartDate = new Date(today.getFullYear() - 1, 0, 1);
          previousEndDate = new Date(today.getFullYear() - 1, 11, 31);
        }

        const dateTimeFrom = startDate.toISOString();
        const dateTimeTo = endDate.toISOString();
        const previousDateTimeFrom = previousStartDate.toISOString();
        const previousDateTimeTo = previousEndDate.toISOString();

        const requests = this.meteringPointIds.flatMap((meteringPointId) => [
          axios.get(`/devices/${meteringPointId}/metering-point-values`, {
            params: {
              dateTimeFrom,
              dateTimeTo,
              aggregateWindowDuration: "1",
              aggregateWindowDurationUnit: this.comparisonMode === 'month' ? "DAYS" : "MONTHS"
            }
          }),
          axios.get(`/devices/${meteringPointId}/metering-point-values`, {
            params: {
              dateTimeFrom: previousDateTimeFrom,
              dateTimeTo: previousDateTimeTo,
              aggregateWindowDuration: "1",
              aggregateWindowDurationUnit: this.comparisonMode === 'month' ? "DAYS" : "MONTHS"
            }
          }),
        ]);

        const responses = await Promise.all(requests);
        const combinedData = {
          energyConsumption: { currentPeriod: 0, previousPeriod: 0 },
          co2EmissionTotal: { currentPeriod: 0, previousPeriod: 0 },
        };

        responses.forEach((response, index) => {
          const isCurrent = index % 2 === 0;
          const periodLabel = isCurrent ? 'currentPeriod' : 'previousPeriod';

          response.data.forEach(item => {
            const type = item.field;
            const value = parseFloat(item.value);

            if (type === "meter_energy_consumption") {
              combinedData.energyConsumption[periodLabel] += value;
            } else if (type === "meter_co2_emission_total") {
              combinedData.co2EmissionTotal[periodLabel] += value;
            }
          });
        });

        // Update both charts at once
        Object.keys(combinedData).forEach(type => {
          const formattedCurrent = formatUnit(combinedData[type].currentPeriod, type === 'energyConsumption' ? 'kWh' : 'g');
          const formattedPrevious = formatUnit(combinedData[type].previousPeriod, type === 'energyConsumption' ? 'kWh' : 'g');

          const data = [
            {
              name: this.formatPeriodLabel(false),
              y: parseFloat(formattedPrevious.value),
              unit: formattedPrevious.unit
            },
            {
              name: this.formatPeriodLabel(true),
              y: parseFloat(formattedCurrent.value),
              unit: formattedCurrent.unit
            }
          ];

          this[`${type}ChartOptions`] = {
            ...this.getDefaultChartOptions(),
            series: [{
              data,
            }],
            tooltip: {
              useHTML: true,
              style: {
                fontSize: '0.85rem',
              },
              formatter: function () {
                return `<b>${this.point.name}:</b> ${Highcharts.numberFormat(this.y, 2)} ${this.point.unit}`;
              }
            }
          };
        });
      } catch (error) {
        console.error('Error fetching metering point data:', error);
      } finally {
        this.loading = false;
      }
    },

    formatPeriodLabel(isCurrent) {
      const today = new Date();
      if (this.comparisonMode === 'month') {
        const month = isCurrent ? today.getMonth() : today.getMonth() - 1;
        const year = isCurrent ? today.getFullYear() : today.getFullYear() - (month < 0 ? 1 : 0);
        const monthName = new Date(year, month < 0 ? 11 : month).toLocaleString('default', { month: 'long' });
        return `${monthName} ${year}`;
      } else {
        return isCurrent ? `${today.getFullYear()}` : `${today.getFullYear() - 1}`;
      }
    },
  }
};
</script>

<style scoped>
.dashboard-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.chart-box {
  border: 1px solid #e7eaec;
  padding: 15px;
  border-radius: 5px;
  background: #fff;
  position: relative;
  min-width: 300px;
  height: 100%;
}

.chart-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #888;
  text-align: center;
  z-index: 3;
}

.greyed-out-chart {
  opacity: 0.3;
  position: relative;
}

.chart-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #000;
  text-align: center;
  z-index: 4;
}
</style>
