<template>
  <span class="subgrid">
    <!-- Energy Consumption Chart -->
    <div class="chart-box" :class="{ 'greyed-out-chart': !energyConsumptionChartOptions.series[0]?.data.some(data => data.y > 0) }">
      <h6>{{ $t('dashboard.heatingDashboard.energyConsumption') }}</h6>
      <div class="chart-placeholder" v-if="!energyConsumptionChartOptions.series[0]?.data.some(data => data.y > 0)" style="z-index: 2;">
        {{ $t('dashboard.noData') }}
      </div>
      <div class="mt-auto mb-auto">
        <highcharts :options="energyConsumptionChartOptions" />
      </div>
    </div>

    <!-- Return Temperature Gauges (Current and Previous Period) -->
    <div class="chart-box">
      <h6>{{ $t('dashboard.heatingDashboard.returnTemperature') }}</h6>
      <div class="gauge-container">
        <!-- Current Period Gauge -->
        <div class="gauge-item" :class="{ 'greyed-out-chart': returnTemperatureGaugeCurrentOptions.series[0]?.data[0] === null }">
          <div class="gauge-placeholder" v-if="returnTemperatureGaugeCurrentOptions.series[0]?.data[0] === null" style="z-index: 2;">
            {{ $t('dashboard.noData') }}
          </div>
          <highcharts :options="returnTemperatureGaugeCurrentOptions" />
        </div>
        
        <!-- Previous Period Gauge -->
        <div class="gauge-item" :class="{ 'greyed-out-chart': returnTemperatureGaugePreviousOptions.series[0]?.data[0] === null }">
          <div class="gauge-placeholder" v-if="returnTemperatureGaugePreviousOptions.series[0]?.data[0] === null" style="z-index: 2;">
            {{ $t('dashboard.noData') }}
          </div>
          <highcharts :options="returnTemperatureGaugePreviousOptions" />
        </div>
      </div>
    </div>
  </span>
</template>


<script>
import axios from 'axios';
import Highcharts from 'highcharts';
import solidGauge from 'highcharts/modules/solid-gauge';

solidGauge(Highcharts);

export default {
  props: {
    meteringPointIds: {
      type: Array,
      required: true
    },
    comparisonMode: {
      type: String,
      required: true
    },
    getDefaultChartOptions: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      energyConsumptionChartOptions: this.getDefaultChartOptions(),
      returnTemperatureGaugeCurrentOptions: this.getReturnTemperatureGaugeOptions(this.formatPeriodLabel(true)),
      returnTemperatureGaugePreviousOptions: this.getReturnTemperatureGaugeOptions(this.formatPeriodLabel(false))
    };
  },
  created() {
    if (this.meteringPointIds?.length) {
      this.fetchMeteringPointData();
    }
  },
  watch: {
    meteringPointIds: {
      handler(newVal) {
        if (newVal?.length && !this.loading) {
          this.fetchMeteringPointData();
        }
      }
    },
    comparisonMode: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && !this.loading && this.meteringPointIds?.length) {
          this.fetchMeteringPointData();
        }
      }
    }
  },
  methods: {
    getReturnTemperatureGaugeOptions(titleSuffix) {
      return {
        chart: {
          type: 'solidgauge',
          height: '80%'
        },
        title: null,
        pane: {
          center: ['50%', '85%'],
          size: '100%',
          startAngle: -90,
          endAngle: 90,
          background: {
            backgroundColor: Highcharts.defaultOptions.legend?.backgroundColor || '#fafafa',
            borderRadius: 5,
            innerRadius: '60%',
            outerRadius: '100%',
            shape: 'arc'
          }
        },
        exporting: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        yAxis: {
          min: 0,
          max: 80,
          stops: [
            [39/80, '#55BF3B'],
            [43/80, '#DF5353'],
          ],
          lineWidth: 0,
          tickWidth: 2,
          tickLength: 10,
          tickPosition: 'inside',
          minorTickInterval: 1,
          minorTickLength: 5,
          minorTickWidth: 1,
          minorTickColor: '#000',
          minorTickPosition: 'inside',
          tickAmount: 9,
          labels: {
            distance: 20,
            style: {
              fontSize: '12px'
            },
            zIndex: 90
          },
          zIndex: 90,
        },
        plotOptions: {
          solidgauge: {
            borderRadius: 3,
            dataLabels: {
              y: 5,
              borderWidth: 0,
              useHTML: true,
              format: `
                <div style="text-align:center">
                  <div style="font-size:25px">
                    <span>{y:.1f}</span>
                    <span style="font-size:25px">°C</span>
                  </div>
                  <span style="font-size:12px;">${titleSuffix}</span>
                </div>`
            },
            rounded: false
          }
        },
        credits: {
          enabled: false
        },
        series: [
          {
            name: 'Return Temperature',
            data: [null],
            tooltip: {
              valueSuffix: ' °C'
            }
          }
        ]
      };
    },
    async fetchMeteringPointData() {
      if (this.loading || !this.meteringPointIds?.length) {
        return;
      }

      this.loading = true;
      try {
        const { dateTimeFrom, dateTimeTo, previousDateTimeFrom, previousDateTimeTo } = this.calculateDateRanges();

        const requests = this.meteringPointIds.flatMap((meteringPointId) => [
          axios.get(`/devices/${meteringPointId}/metering-point-values`, {
            params: {
              dateTimeFrom,
              dateTimeTo,
              aggregateWindowDuration: "1",
              aggregateWindowDurationUnit: this.comparisonMode === 'month' ? "DAYS" : "MONTHS"
            }
          }),
          axios.get(`/devices/${meteringPointId}/metering-point-values`, {
            params: {
              dateTimeFrom: previousDateTimeFrom,
              dateTimeTo: previousDateTimeTo,
              aggregateWindowDuration: "1",
              aggregateWindowDurationUnit: this.comparisonMode === 'month' ? "DAYS" : "MONTHS"
            }
          }),
        ]);

        const responses = await Promise.all(requests);
        const combinedData = {
          energyConsumption: { currentPeriod: 0, previousPeriod: 0 },
          returnTemperature: { currentPeriod: null, previousPeriod: null }
        };

        responses.forEach((response, index) => {
          const isCurrent = index % 2 === 0;
          const periodLabel = isCurrent ? 'currentPeriod' : 'previousPeriod';

          response.data.forEach(item => {
            const type = item.field;
            const value = parseFloat(item.value);

            if (type === "meter_energy_consumption_total") {
              combinedData.energyConsumption[periodLabel] += value;
            } else if (type === "meter_return_temperature") {
              combinedData.returnTemperature[periodLabel] = value;
            }
          });
        });

        // Update all charts at once
        this.updateCharts(combinedData);
      } catch (error) {
        console.error('Error fetching metering point data:', error);
      } finally {
        this.loading = false;
      }
    },

    calculateDateRanges() {
      const today = new Date();
      let startDate, endDate, previousStartDate, previousEndDate;

      if (this.comparisonMode === 'month') {
        startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        endDate = new Date(today);
        previousStartDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        previousEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
      } else {
        startDate = new Date(today.getFullYear(), 0, 1);
        endDate = new Date(today);
        previousStartDate = new Date(today.getFullYear() - 1, 0, 1);
        previousEndDate = new Date(today.getFullYear() - 1, 11, 31);
      }

      return {
        dateTimeFrom: startDate.toISOString(),
        dateTimeTo: endDate.toISOString(),
        previousDateTimeFrom: previousStartDate.toISOString(),
        previousDateTimeTo: previousEndDate.toISOString()
      };
    },

    updateCharts(combinedData) {
      const sortedPeriods = ['previousPeriod', 'currentPeriod'];

      // Update energy consumption chart
      this.energyConsumptionChartOptions = {
        ...this.getDefaultChartOptions(),
        series: [{
          data: sortedPeriods.map(period => ({
            name: period === 'currentPeriod' ? this.formatPeriodLabel(true) : this.formatPeriodLabel(false),
            y: combinedData.energyConsumption[period]
          })),
        }],
        tooltip: {
          useHTML: true,
          style: {
            fontSize: '0.85rem',
          },
          formatter: function() {
            return `<b>${this.point.name}:</b> ${Highcharts.numberFormat(this.y, 1)} kWh`;
          }
        },
      };

      // Update temperature gauges
      this.returnTemperatureGaugeCurrentOptions = this.getReturnTemperatureGaugeOptions(this.formatPeriodLabel(true));
      this.returnTemperatureGaugeCurrentOptions.series[0].data = [combinedData.returnTemperature.currentPeriod];
      
      this.returnTemperatureGaugePreviousOptions = this.getReturnTemperatureGaugeOptions(this.formatPeriodLabel(false));
      this.returnTemperatureGaugePreviousOptions.series[0].data = [combinedData.returnTemperature.previousPeriod];
    },

    formatPeriodLabel(isCurrent) {
      const today = new Date();
      if (this.comparisonMode === 'month') {
        const month = isCurrent ? today.getMonth() : today.getMonth() - 1;
        const year = isCurrent ? today.getFullYear() : today.getFullYear() - (month < 0 ? 1 : 0);
        const monthName = new Date(year, month < 0 ? 11 : month).toLocaleString('default', { month: 'long' });
        return `${monthName} ${year}`;
      } else {
        return isCurrent ? `${today.getFullYear()}` : `${today.getFullYear() - 1}`;
      }
    }
  }
};
</script>


<style scoped>
.dashboard-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.subgrid {
  display: contents;
}

.chart-box {
  display: flex;
  flex-direction: column;
  border: 1px solid #e7eaec;
  padding: 15px;
  border-radius: 5px;
  background: #fff;
  position: relative;
  height: 100%;
}

.chart-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #888;
  text-align: center;
  z-index: 3;
}

.greyed-out-chart {
  opacity: 0.3;
}

.gauge-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #888;
  text-align: center;
  z-index: 3;
}

.gauge-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%; /* Ensure the container fills the entire chart-box */
  width: 100%;
}

.gauge-item {
  width: 80%; /* Set width to fit both gauges side-by-side within the chart box */
  height: 50%;
}
</style>