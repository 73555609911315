<template>
  <div>
    <div class="col equal-height">
      <!-- Building Analysis Dashboard -->
      <transition :duration="{ enter: 0, leave: 0 }" enter-active-class="slideInLeft animated">
        <div class="col-lg-12">
          <div class="ibox">
            <div class="ibox-title">
              <div class="title-wrapper">
                <h5>{{ building.name }} - {{ $t('dashboard.energyDashboard.title') }}</h5>
                <!-- Month/Year Selector Buttons -->
                <div class="top-right-controls">
                  <button @click="setComparisonMode('month')" :class="{ active: internalComparisonMode === 'month' }">
                    {{ $t('dashboard.options.month') }}
                  </button>
                  <button @click="setComparisonMode('year')" :class="{ active: internalComparisonMode === 'year' }">
                    {{ $t('dashboard.options.year') }}
                  </button>
                </div>
              </div>
            </div>
            <div class="ibox-content">
              <div class="dashboard-container">
                <energy-dashboard
                  :meteringPointIds="meteringPoints.filter(point => point.meteringPointSubtype === 'ELECTRICITY').map(point => point.id)"
                  :comparisonMode="internalComparisonMode"
                  :getDefaultChartOptions="getDefaultChartOptions"
                />
              </div>
            </div>
            <div class="ibox mt-2">
              <div class="ibox-title py-4">
                <h5>{{ $t('dashboard.other.title') }}</h5>
              </div>
              <div class="ibox-content">
                <div class="dashboard-container">
                  <water-dashboard
                    :meteringPointIds="meteringPoints.filter(point => point.meteringPointSubtype === 'WATER').map(point => point.id)"
                    :comparisonMode="internalComparisonMode"
                    :getDefaultChartOptions="getDefaultChartOptions"
                  />
                  <heating-dashboard
                    :meteringPointIds="meteringPoints.filter(point => point.meteringPointSubtype === 'DISTRICT_HEATING').map(point => point.id)"
                    :comparisonMode="internalComparisonMode"
                    :getDefaultChartOptions="getDefaultChartOptions"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapActions } from "vuex";
import EnergyDashboard from "./EnergyDashboard.vue";
import WaterDashboard from "./WaterDashboard.vue";
import HeatingDashboard from "./HeatingDashboard.vue";

export default {
  components: {
    WaterDashboard,
    EnergyDashboard,
    HeatingDashboard,
  },
  props: {
    building: {
      type: Object,
      required: true,
    },
    comparisonMode: {
      type: String,
      default: 'month',
    },
  },
  data() {
    return {
      internalComparisonMode: this.comparisonMode,
      meteringPoints: [],
    };
  },
  computed: {
    ...mapState({
      contextOrg: (state) => state.contextOrg,
    }),
  },
  watch: {
    building: {
      immediate: true,
      handler(newBuilding) {
        if (newBuilding && newBuilding.id) {
          this.fetchMeteringPoints(newBuilding.id);
        }
      },
    },
  },
  methods: {
    ...mapActions({
      // fetchBuildingMeteringPoints: "devices/fetchBuildingMeteringPoints",
    }),
    setComparisonMode(mode) {
      this.internalComparisonMode = mode;
    },
    fetchMeteringPoints(buildingId) {
      this.fetchBuildingMeteringPoints(buildingId)
        .then(() => {
          this.meteringPoints = this.$store.state.devices.meters;
        })
        .catch((error) => {
          console.error("Error fetching metering points for building:", error);
        });
    },
    fetchBuildingMeteringPoints(id){
      Vue.axios.get('/admin/buildings/' + id + '/devices', { params: { type: "METERING_POINT" }}).then(response => {
        this.meteringPoints = response.data
        }).catch(error => {
            console.log(error);
        })
    },
    getDefaultChartOptions() {
      return {
        chart: {
          type: 'column',
        },
        title: {
          text: '',
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: undefined,
          },
        },
        series: [],
        legend: { enabled: false },
        credits: {
          enabled: false
        },
      };
    },
  },
  created() {
    if (this.building && this.building.id) {
      this.fetchMeteringPoints(this.building.id);
    }
  },
};
</script>

<style scoped>
.dashboard-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.grid-item {
  border: 1px solid #e7eaec;
  padding: 15px;
  border-radius: 5px;
  background: #fff;
  position: relative;
}

.chart-box {
  border: 1px solid #e7eaec;
  padding: 15px;
  border-radius: 5px;
  background: #fff;
  position: relative;
  height: 100%;
}
.chart-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #888;
  text-align: center;
  z-index: 3;
}
.greyed-out-chart {
  opacity: 0.3;
  position: relative;
}

.chart-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #000;
  text-align: center;
  z-index: 4;
}
.top-right-controls {
  display: flex;
  margin-bottom: 15px;
  margin-left: auto;
}
.top-right-controls button {
  padding: 10px 20px;
  border: none;
  background-color: #8FC6CC;
  color: #fff;
  cursor: pointer;
  width: 100px;
}
.top-right-controls button.active {
  background-color: #61AEB7;
}
.title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
