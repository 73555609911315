<template>
  <div>
    <b-tabs v-model="currentPage" content-class="px-5 py-5 white-bg">

      <!-- Energy Analysis Dashboard Tab -->
      <b-tab :title="$t('dashboard.energyDashboard.title')" lazy>
        <div class="col-lg-12" v-if="contextOrg">
          <div class="dashboard-wrapper">
            <div class="title-wrapper">
              <!-- <h5>{{ $t('dashboard.energyDashboard.title') }}</h5> -->
              <!-- Month/Year Selector Buttons -->
              <div class="top-right-controls">
                <button @click="setComparisonMode('month')" :class="{ active: comparisonMode === 'month' }">
                  {{ $t('dashboard.options.month') }}
                </button>
                <button @click="setComparisonMode('year')" :class="{ active: comparisonMode === 'year' }">
                  {{ $t('dashboard.options.year') }}
                </button>
            </div>
          </div>
            <div class="dashboard-container">
              <energy-dashboard
                :meteringPointIds="meteringPoints.filter(point => point.meteringPointSubtype === 'ELECTRICITY').map(point => point.id)"
                :comparisonMode="comparisonMode"
                :getDefaultChartOptions="getDefaultChartOptions"
              />
            </div>
            <!-- <div class="ibox-title py-4">
              <h5>{{ $t('dashboard.other.title') }}</h5>
            </div> -->
            <div class="dashboard-container mt-4">
              <water-dashboard
                :meteringPointIds="meteringPoints.filter(point => point.meteringPointSubtype === 'WATER').map(point => point.id)"
                :comparisonMode="comparisonMode"
                :getDefaultChartOptions="getDefaultChartOptions"
              />
              <heating-dashboard
                :meteringPointIds="meteringPoints.filter(point => point.meteringPointSubtype === 'DISTRICT_HEATING').map(point => point.id)"
                :comparisonMode="comparisonMode"
                :getDefaultChartOptions="getDefaultChartOptions"
              />
            </div>
          </div>
        </div>
      </b-tab>

      <!-- Table Breakdown Tab -->
      <b-tab :title="$t('dashboard.table.title')" lazy>
        <div class="table-breakdown">
          <div class="dashboard-wrapper">
            <div class="title-wrapper">
              <!-- <h5>{{ $t('dashboard.table.title') }}</h5> -->
              <!-- Month/Year Selector Buttons -->
              <div class="top-right-controls">
                  <button @click="setComparisonMode('month')" :class="{ active: comparisonMode === 'month' }">
                    {{ $t('dashboard.options.month') }}
                  </button>
                  <button @click="setComparisonMode('year')" :class="{ active: comparisonMode === 'year' }">
                    {{ $t('dashboard.options.year') }}
                  </button>
              </div>
            </div>
                <building-table
                :buildingsData="buildingsData"
                :comparisonMode="comparisonMode"
                :title="$t('dashboard.energyDashboard.title')"
                @set-comparison-mode="setComparisonMode"
                />
            </div>
          </div>
      </b-tab>

    </b-tabs>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EnergyDashboard from "./EnergyDashboard.vue";
import WaterDashboard from "./WaterDashboard.vue";
import HeatingDashboard from "./HeatingDashboard.vue";
import BuildingTable from "./BuildingTable.vue";

export default {
  components: {
    WaterDashboard,
    EnergyDashboard,
    HeatingDashboard,
    BuildingTable
  },
  data() {
    return {
      comparisonMode: 'month', // Default comparison mode
      currentPage: 0, // Default to the first tab
      buildingsData: [
        { name: 'Building 1', energyUseIntensity: 36, electricity: 25, districtHeating: 156, gas: 0, water: 102, hvac: 63, tailIndex: 'III', energyTrend: '-', temperature: 501 },
      ],
    };
  },
  computed: {
    ...mapState({
      contextOrg: (state) => state.contextOrg,
      meteringPoints: (state) => state.devices.meters,
    }),
  },
  watch: {
    contextOrg: {
      immediate: true,
      handler(newOrg) {
        if (newOrg) {
          // Fetch organisation metering points when contextOrg changes
          this.$store.dispatch('devices/fetchOrganisationMeteringPoints', newOrg.id).then(() => {});
        }
      }
    },
    meteringPoints: {
      immediate: true,
      handler() {
        // Fetch metering point data once metering points are fetched
      },
    },
  },
  methods: {
    setComparisonMode(mode) {
      this.comparisonMode = mode;
    },
    getDefaultChartOptions() {
      return {
        chart: {
          type: 'column',
        },
        title: {
          text: '',
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: undefined,
          },
        },
        series: [],
        legend: { enabled: false },
        credits: {
          enabled: false
        },
      };
    },
  },
};
</script>

<style scoped>

.dashboard-wrapper {
  display: flex;
  flex-direction: column;
}

.dashboard-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.grid-item {
  border: 1px solid #e7eaec;
  padding: 15px;
  border-radius: 5px;
  background: #fff;
  position: relative;
}

.chart-box {
  border: 1px solid #e7eaec;
  padding: 15px;
  border-radius: 5px;
  background: #fff;
  position: relative;
  height: 100%;
}
.chart-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #888;
  text-align: center;
  z-index: 3;
}
.greyed-out-chart {
  opacity: 0.3;
  position: relative;
}

.chart-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #000;
  text-align: center;
  z-index: 4;
}
.top-right-controls {
  display: flex;
  margin-bottom: 15px;
  margin-left: auto;
}
.top-right-controls button {
  padding: 10px 20px;
  border: none;
  background-color: #8FC6CC;
  color: #fff;
  cursor: pointer;
  width: 100px;
}
.top-right-controls button.active {
  background-color: #61AEB7;
}
.table-breakdown {
  margin-top: 20px;
}
.table-breakdown table {
  width: 100%;
  border-collapse: collapse;
}
.table-breakdown th, .table-breakdown td {
  border: 1px solid #e7eaec;
  padding: 10px;
  text-align: left;
}
.table-breakdown th {
  background-color: #f5f5f5;
}

.title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
